/**
 * Configurazione del Router
 */

let firstPageVisited = true;
angular.module("app")
    .run(
        ["$rootScope", "$state", "$stateParams",
            ($rootScope: any, $state: any, $stateParams: any) => {
                $rootScope.$state = $state;
                $rootScope.$stateParams = $stateParams;
            }
        ]
    )
    .config(
        ["$stateProvider", "$urlRouterProvider",
            ($stateProvider: any, $urlRouterProvider: any) => {
                $urlRouterProvider
                    // Se nessuna pagina particolare viene richiesta, allora si va all'autenticazione
                    .otherwise("/app/login");

                $stateProvider
                    .state("app", {
                        abstract: true,
                        url: "/app",
                        templateUrl: "app/shared/global/commonServices.html",
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/login/UserService.js'
                                    ]);
                                }]
                        }
                    })

                    .state("app.access", {
                        abstract: true,
                        url: "/access",
                        template: "<div ui-view class=\"fade-in-right-big smooth\"></div>"
                    })

                    // State posto fra l'autenticazione e la prima pagina dell'applicativo
                    .state('app.intermediate', {
                        url: '/intermediate',
                        templateUrl: 'app/routes/structure/intermediate/intermediate.html',
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/routes/structure/intermediate/IntermediateController.js']);
                                }]
                        }
                    })

                    // Pagina di errore, browser o device non supportati
                    .state("app.compatibilityError", {
                        url: "/compatibilityError",
                        templateUrl: "app/routes/compatibilityError/compatibilityError.html"
                    })

                    // Pagina di Login SAML
                    .state("app.login", {
                        url: "/login",
                        templateUrl: "app/routes/login/ssoLogin.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/SsoLoginController.js"]);
                                }]
                        }
                    })

                    // Pagina di Logout SAML
                    .state("app.logoutCompleted", {
                        url: "/logoutCompleted",
                        templateUrl: "app/routes/login/logoutCompleted.html"
                    })

                    // Pagina di Errore Login SAML
                    .state("app.samlError", {
                        url: "/samlError",
                        templateUrl: "app/routes/login/samlError.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/SamlErrorController.js"]);
                                }]
                        }
                    })

                    // Pagina di Login (per amministratori che non fanno login con SSO)
                    .state("app.adminLogin", {
                        url: "/adminLogin",
                        templateUrl: "app/routes/login/adminLogin.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/LoginController.js"]);
                                }]
                        }
                    })
                    // Alias per quella di adminLogin
                    .state("app.localLogin", {
                        url: "/localLogin",
                        templateUrl: "app/routes/login/adminLogin.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/LoginController.js"]);
                                }]
                        }
                    })

                    // State astratto che conterrà l'applicativo, l'header e la sidebar
                    .state('app.customersinvitationsApp', {
                        abstract: true,
                        url: '/customersinvitations',
                        templateUrl: 'app/routes/structure/customersinvitations/customersinvitations.html',
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    // Tanto vale caricare il modulo socialshare qui, visto che è una dipendenza di MaterialsManager, che è utilizzato ovunque. Stessa cosa per le icone e per la tabella
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'vendor/angular-material-icons/angular-material-icons.css',
                                        'vendor/angular-socialshare/angular-socialshare.min.js',
                                        'vendor/angular-material-data-table/md-data-table.css',
                                        'vendor/angular-material-data-table/md-data-table.min.js',
                                        'vendor/angular-material-icons/angular-material-icons.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/config/socialshare.module.js',
                                                'js/config/ngMdIcons.module.js',
                                                'js/config/md.data.table.module.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/shared/header/header.js',
                                                        'js/shared/profileSelected/profileSelected.js',
                                                        'js/shared/footer/footer.js',
                                                        'js/routes/structure/customersinvitations/CustomersinvitationsController.js'])
                                                        .then(() => {
                                                            $rootScope.forceLoadSpinner = false;
                                                        })
                                                })
                                        })
                                }]
                        }
                    })

                    // Definizione della pagina contenente la lista delle richieste dei relatori
                    .state('app.customersinvitationsApp.trainerRequests', {
                        url: '/trainerRequests',
                        views: {
                            "content": { templateUrl: 'app/routes/trainerRequests/trainerRequests.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/shared/trainerRequestsTable/trainerRequestsTable.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'app/shared/modals/confirmModal.html',
                                                'js/shared/global/EditionsService.js',
                                                'js/routes/manageEdition/ManageEditionService.js',
                                                'js/routes/modules/ModulesService.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'js/shared/trainingsManager/TrainingsManager.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/routes/trainerRequests/TrainerRequestsController.js',
                                                        'js/routes/trainerRequests/TrainerRequestsService.js'])
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_APPROVE_TEACHERS'
                        }
                    })

                    // Definizione della pagina contenente la lista dei template
                    .state('app.customersinvitationsApp.templates', {
                        url: '/templates',
                        views: {
                            "content": { templateUrl: 'app/routes/templates/templates.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                function ($ocLazyLoad: any) {
                                    return $ocLazyLoad.load([
                                        'js/routes/templates/TemplatesController.js',
                                        'js/routes/templates/TemplatesService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_VIEW_TEMPLATE'
                        }
                    })

                    // Definizione della pagina per la creazione di un nuovo template mail
                    .state('app.customersinvitationsApp.createTemplate', {
                        url: '/createTemplate',
                        views: {
                            "content": { templateUrl: 'app/routes/createTemplate/createTemplate.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                'vendor/ng-file-upload/ng-file-upload.min.js',
                                                                'assets/js/grapesjs/js/object-assign-polyfill.js',
                                                                'assets/js/grapesjs/css/grapesjs-plugin-filestack.css',
                                                                'assets/js/grapesjs/css/grapesjs-preset-webpage.min.css',
                                                                'assets/js/grapesjs/css/toastr.min.css',
                                                                'assets/js/grapesjs/css/tooltip.css',
                                                                'assets/js/grapesjs/css/grapes.min.css'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load(['js/config/froala.module.js',
                                                                        'assets/js/grapesjs/js/grapes.min.js'])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/upload/UploadService.js',
                                                                                'assets/js/grapesjs/js/aviary-editor.js',
                                                                                'assets/js/grapesjs/js/feathercontrols_en.js',
                                                                                'assets/js/grapesjs/js/filestack.js',
                                                                                'assets/js/grapesjs/js/grapesjs-lory-slider.min.js',
                                                                                'assets/js/grapesjs/js/grapesjs-present-webpage.min.js',
                                                                                'assets/js/grapesjs/js/grapesjs-tabs.min.js',
                                                                                'assets/js/grapesjs/js/toastr.min.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load([
                                                                                        'js/routes/createTemplate/CreateTemplateController.js',
                                                                                        'js/routes/createTemplate/CreateTemplateService.js'])
                                                                                })
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_TEMPLATE'
                        }
                    })

                    // Definizione della pagina per l'editing di un template mail
                    .state('app.customersinvitationsApp.editTemplate', {
                        url: '/editTemplate/:textTemplateId',
                        views: {
                            "content": { templateUrl: 'app/routes/editTemplate/editTemplate.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'assets/js/grapesjs/js/object-assign-polyfill.js',
                                                                'assets/js/grapesjs/css/grapesjs-plugin-filestack.css',
                                                                'assets/js/grapesjs/css/grapesjs-preset-webpage.min.css',
                                                                'assets/js/grapesjs/css/toastr.min.css',
                                                                'assets/js/grapesjs/css/tooltip.css',
                                                                'assets/js/grapesjs/css/grapes.min.css'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load(['js/config/froala.module.js',
                                                                        'assets/js/grapesjs/js/grapes.min.js',
                                                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/upload/UploadService.js',
                                                                                'assets/js/grapesjs/js/aviary-editor.js',
                                                                                'assets/js/grapesjs/js/feathercontrols_en.js',
                                                                                'assets/js/grapesjs/js/filestack.js',
                                                                                'assets/js/grapesjs/js/grapesjs-lory-slider.min.js',
                                                                                'assets/js/grapesjs/js/grapesjs-present-webpage.min.js',
                                                                                'assets/js/grapesjs/js/grapesjs-tabs.min.js',
                                                                                'assets/js/grapesjs/js/toastr.min.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load([
                                                                                        'js/routes/editTemplate/EditTemplateController.js',
                                                                                        'js/routes/editTemplate/EditTemplateService.js']);
                                                                                })
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_TEMPLATE'
                        }
                    })

                    // Definizione della della pagina di home per l'amministratore
                    .state('app.customersinvitationsApp.home', {
                        url: '/home',
                        views: {
                            "content": { templateUrl: 'app/routes/home/home.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load(['js/routes/home/HomeBoxManager.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/shared/global/EditionsService.js',
                                                'js/shared/editionsManager/EditionsManager.js',
                                                'js/shared/homeBox/homeBox.js',
                                                'js/shared/meetingsTable/meetingsTable.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/routes/home/HomeController.js',
                                                        'js/routes/home/HomeService.js'])
                                                        .then(() => {
                                                            $rootScope.forceLoadSpinner = false;
                                                        })
                                                })
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_VIEW_SELF_INFO'
                        }
                    })

                    // Definizione della pagina con la lista di Meeting on demand online lato Agenzia
                    .state('app.customersinvitationsApp.onlineOnDemandMeeting', {
                        url: '/onlineOnDemandMeeting',
                        views: {
                            "content": { templateUrl: 'app/routes/meeting/meeting.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/global/EditionsService.js',
                                        'js/shared/editionsManager/EditionsManager.js',
                                        'js/shared/meetingsFilters/meetingsFilters.js',
                                        'js/shared/meetingsTable/meetingsTable.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/routes/meeting/MeetingService.js',
                                                'js/routes/meeting/MeetingController.js'])
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina con la lista di Meeting lato Agenzia
                    .state('app.customersinvitationsApp.meeting', {
                        url: '/meeting',
                        views: {
                            "content": { templateUrl: 'app/routes/meeting/meeting.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/global/EditionsService.js',
                                        'js/shared/editionsManager/EditionsManager.js',
                                        'js/shared/meetingsFilters/meetingsFilters.js',
                                        'js/shared/meetingsTable/meetingsTable.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/routes/meeting/MeetingService.js',
                                                'js/routes/meeting/MeetingController.js'])
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina per la creazione di una nuova campagna
                    .state('app.customersinvitationsApp.newDirectional', {
                        url: '/newDirectional',
                        views: {
                            "content": { templateUrl: 'app/routes/manageModule/manageModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'vendor/material-steppers/material-steppers.css',
                                                                'assets/js/material-steppers/material-steppers.js',
                                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                'vendor/ng-file-upload/ng-file-upload.min.js'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load([
                                                                        'js/config/froala.module.js'
                                                                    ])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                                'js/shared/wizardSteps/moduleImage/moduleImage.js',
                                                                                'js/shared/wizardSteps/voucherTemplatePdf/voucherTemplatePdf.js',
                                                                                'js/shared/wizardSteps/moduleLandingPageImage/moduleLandingPageImage.js',
                                                                                'js/shared/wizardSteps/moduleEmailImage/moduleEmailImage.js',
                                                                                'js/shared/toArray/toArray.js',
                                                                                'js/shared/moduleScheduleManager/ModuleScheduleManager.js',
                                                                                'js/routes/modules/ModulesService.js',
                                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                                'js/shared/upload/UploadService.js',
                                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                                'app/shared/modals/confirmModal.html',
                                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                                'js/shared/wizardSteps/moduleDetails/moduleDetails.js',
                                                                                'js/shared/wizardSteps/moduleSchedule/moduleSchedule.js',
                                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                                'js/shared/wizardSteps/reminder/reminder.js',
                                                                                'js/shared/wizardSteps/uploadMaterials/uploadMaterials.js',
                                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                                'js/shared/wizardSteps/samplePath/samplePath.js',
                                                                                'js/shared/wizardSteps/communicationKit/communicationKit.js',
                                                                                'js/shared/wizardSteps/uploadedMaterials/uploadedMaterials.js',
                                                                                'js/shared/wizardSteps/moduleVisibility/moduleVisibility.js',
                                                                                'js/shared/actionsHeader/actionsHeader.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load('js/routes/manageModule/ManageModuleController.js')
                                                                                })
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina per la modifica di una campagna
                    .state('app.customersinvitationsApp.editDirectional', {
                        url: '/editDirectional/:courseId',
                        views: {
                            "content": { templateUrl: 'app/routes/manageModule/manageModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'vendor/material-steppers/material-steppers.css',
                                                                'assets/js/material-steppers/material-steppers.js',
                                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                'vendor/ng-file-upload/ng-file-upload.min.js'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load([
                                                                        'js/config/froala.module.js'
                                                                    ])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                                'js/shared/wizardSteps/moduleImage/moduleImage.js',
                                                                                'js/shared/wizardSteps/voucherTemplatePdf/voucherTemplatePdf.js',
                                                                                'js/shared/wizardSteps/moduleLandingPageImage/moduleLandingPageImage.js',
                                                                                'js/shared/wizardSteps/moduleEmailImage/moduleEmailImage.js',
                                                                                'js/shared/toArray/toArray.js',
                                                                                'js/shared/moduleScheduleManager/ModuleScheduleManager.js',
                                                                                'js/routes/modules/ModulesService.js',
                                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                                'js/shared/upload/UploadService.js',
                                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                                'app/shared/modals/confirmModal.html',
                                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                                'js/shared/wizardSteps/moduleDetails/moduleDetails.js',
                                                                                'js/shared/wizardSteps/moduleSchedule/moduleSchedule.js',
                                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                                'js/shared/wizardSteps/reminder/reminder.js',
                                                                                'js/shared/wizardSteps/uploadMaterials/uploadMaterials.js',
                                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                                'js/shared/wizardSteps/samplePath/samplePath.js',
                                                                                'js/shared/wizardSteps/communicationKit/communicationKit.js',
                                                                                'js/shared/wizardSteps/uploadedMaterials/uploadedMaterials.js',
                                                                                'js/shared/wizardSteps/moduleVisibility/moduleVisibility.js',
                                                                                'js/shared/actionsHeader/actionsHeader.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load('js/routes/manageModule/ManageModuleController.js')
                                                                                });
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina per la creazione di un nuovo format
                    .state('app.customersinvitationsApp.newFormat', {
                        url: '/newFormat',
                        views: {
                            "content": { templateUrl: 'app/routes/manageModule/manageModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'vendor/material-steppers/material-steppers.css',
                                                                'assets/js/material-steppers/material-steppers.js',
                                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                'vendor/ng-file-upload/ng-file-upload.min.js'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load([
                                                                        'js/config/froala.module.js'
                                                                    ])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                                'js/shared/wizardSteps/moduleImage/moduleImage.js',
                                                                                'js/shared/wizardSteps/voucherTemplatePdf/voucherTemplatePdf.js',
                                                                                'js/shared/wizardSteps/moduleLandingPageImage/moduleLandingPageImage.js',
                                                                                'js/shared/wizardSteps/moduleEmailImage/moduleEmailImage.js',
                                                                                'js/shared/toArray/toArray.js',
                                                                                'js/shared/moduleScheduleManager/ModuleScheduleManager.js',
                                                                                'js/routes/modules/ModulesService.js',
                                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                                'js/shared/upload/UploadService.js',
                                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                                'app/shared/modals/confirmModal.html',
                                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                                'js/shared/wizardSteps/moduleDetails/moduleDetails.js',
                                                                                'js/shared/wizardSteps/moduleSchedule/moduleSchedule.js',
                                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                                'js/shared/wizardSteps/reminder/reminder.js',
                                                                                'js/shared/wizardSteps/uploadMaterials/uploadMaterials.js',
                                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                                'js/shared/wizardSteps/samplePath/samplePath.js',
                                                                                'js/shared/wizardSteps/communicationKit/communicationKit.js',
                                                                                'js/shared/wizardSteps/uploadedMaterials/uploadedMaterials.js',
                                                                                'js/shared/wizardSteps/moduleVisibility/moduleVisibility.js',
                                                                                'js/shared/actionsHeader/actionsHeader.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load('js/routes/manageModule/ManageModuleController.js')
                                                                                });
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina per la modifica di un format
                    .state('app.customersinvitationsApp.editFormat', {
                        url: '/editFormat/:courseId',
                        views: {
                            "content": { templateUrl: 'app/routes/manageModule/manageModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'vendor/material-steppers/material-steppers.css',
                                                                'assets/js/material-steppers/material-steppers.js',
                                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                'vendor/ng-file-upload/ng-file-upload.min.js'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load([
                                                                        'js/config/froala.module.js'
                                                                    ])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                                'js/shared/wizardSteps/moduleImage/moduleImage.js',
                                                                                'js/shared/wizardSteps/voucherTemplatePdf/voucherTemplatePdf.js',
                                                                                'js/shared/wizardSteps/moduleLandingPageImage/moduleLandingPageImage.js',
                                                                                'js/shared/wizardSteps/moduleEmailImage/moduleEmailImage.js',
                                                                                'js/shared/toArray/toArray.js',
                                                                                'js/shared/moduleScheduleManager/ModuleScheduleManager.js',
                                                                                'js/routes/modules/ModulesService.js',
                                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                                'js/shared/upload/UploadService.js',
                                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                                'app/shared/modals/confirmModal.html',
                                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                                'js/shared/wizardSteps/moduleDetails/moduleDetails.js',
                                                                                'js/shared/wizardSteps/moduleSchedule/moduleSchedule.js',
                                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                                'js/shared/wizardSteps/reminder/reminder.js',
                                                                                'js/shared/wizardSteps/uploadMaterials/uploadMaterials.js',
                                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                                'js/shared/wizardSteps/samplePath/samplePath.js',
                                                                                'js/shared/wizardSteps/communicationKit/communicationKit.js',
                                                                                'js/shared/wizardSteps/uploadedMaterials/uploadedMaterials.js',
                                                                                'js/shared/wizardSteps/moduleVisibility/moduleVisibility.js',
                                                                                'js/shared/actionsHeader/actionsHeader.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load('js/routes/manageModule/ManageModuleController.js')
                                                                                });
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina per la creazione di un nuovo format libero
                    .state('app.customersinvitationsApp.newFreeFormat', {
                        url: '/newFreeFormat',
                        views: {
                            "content": { templateUrl: 'app/routes/manageFreeModule/manageFreeModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/shared/maxlines/maxlines.js',
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'vendor/material-steppers/material-steppers.css',
                                                                'assets/js/material-steppers/material-steppers.js',
                                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                'vendor/ng-file-upload/ng-file-upload.min.js'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load([
                                                                        'js/config/froala.module.js'
                                                                    ])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/wizardSteps/selectFreeFormatImage/selectFreeFormatImage.js',
                                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                                'js/shared/wizardSteps/moduleImage/moduleImage.js',
                                                                                'js/shared/wizardSteps/voucherTemplatePdf/voucherTemplatePdf.js',
                                                                                'js/shared/wizardSteps/moduleLandingPageImage/moduleLandingPageImage.js',
                                                                                'js/shared/wizardSteps/moduleEmailImage/moduleEmailImage.js',
                                                                                'js/shared/toArray/toArray.js',
                                                                                'js/shared/moduleScheduleManager/ModuleScheduleManager.js',
                                                                                'js/routes/modules/ModulesService.js',
                                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                                'js/shared/upload/UploadService.js',
                                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                                'app/shared/modals/confirmModal.html',
                                                                                'app/shared/modals/cardGuideModal.html',
                                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                                'js/shared/wizardSteps/freeModuleMaterialPreviews/freeModuleMaterialPreviews.js',
                                                                                'js/shared/wizardSteps/moduleDetails/moduleDetails.js',
                                                                                'js/shared/wizardSteps/moduleSchedule/moduleSchedule.js',
                                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                                'js/shared/wizardSteps/freeFormatCommunicationsText/freeFormatCommunicationsText.js',
                                                                                'js/shared/wizardSteps/reminder/reminder.js',
                                                                                'js/shared/wizardSteps/uploadMaterials/uploadMaterials.js',
                                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                                'js/shared/wizardSteps/samplePath/samplePath.js',
                                                                                'js/shared/wizardSteps/communicationKit/communicationKit.js',
                                                                                'js/shared/wizardSteps/uploadedMaterials/uploadedMaterials.js',
                                                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                                                'js/shared/global/EditionsService.js',
                                                                                'js/routes/manageEdition/ManageEditionService.js',
                                                                                'js/shared/editionManager/EditionManager.js',
                                                                                'js/shared/wizardSteps/moduleVisibility/moduleVisibility.js',
                                                                                'js/shared/actionsHeader/actionsHeader.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load('js/routes/manageFreeModule/ManageFreeModuleController.js')
                                                                                });
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina per la modifica di un format libero
                    .state('app.customersinvitationsApp.editFreeFormat', {
                        url: '/editFreeFormat/:courseId',
                        views: {
                            "content": { templateUrl: 'app/routes/manageFreeModule/manageFreeModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        'vendor/font-awesome/css/font-awesome.min.css'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/shared/maxlines/maxlines.js',
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                                'vendor/froala/froala_editor.min.css',
                                                'vendor/froala/froala_style.min.css',
                                                'vendor/froala/char_counter.min.css',
                                                'vendor/froala/code_view.min.css',
                                                'vendor/froala/colors.min.css',
                                                'vendor/froala/emoticons.min.css',
                                                'vendor/froala/draggable.min.css',
                                                'vendor/froala/file.min.css',
                                                'vendor/froala/fullscreen.min.css',
                                                'vendor/froala/image.min.css',
                                                'vendor/froala/image_manager.min.css',
                                                'vendor/froala/table.min.css',
                                                'vendor/froala/froala_editor.min.custom.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'vendor/froala/image.min.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'vendor/froala/image_manager.min.js',
                                                                'vendor/froala/align.min.js',
                                                                'vendor/froala/char_counter.min.js',
                                                                'vendor/froala/code_view.min.js',
                                                                'vendor/froala/colors.min.js',
                                                                'vendor/froala/emoticons.min.js',
                                                                'vendor/froala/draggable.min.js',
                                                                'vendor/froala/font_family.min.js',
                                                                'vendor/froala/font_size.min.js',
                                                                'vendor/froala/file.min.js',
                                                                'vendor/froala/fullscreen.min.js',
                                                                'vendor/froala/link.min.js',
                                                                'vendor/froala/lists.min.js',
                                                                'vendor/froala/paragraph_format.min.js',
                                                                'vendor/froala/paragraph_style.min.js',
                                                                'vendor/froala/quote.min.js',
                                                                'vendor/froala/table.min.js',
                                                                'vendor/froala/video.min.js',
                                                                'vendor/froala/it.js',
                                                                'vendor/froala/angular-froala.js',
                                                                'vendor/material-steppers/material-steppers.css',
                                                                'assets/js/material-steppers/material-steppers.js',
                                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                                'vendor/ng-file-upload/ng-file-upload.min.js'
                                                            ])
                                                                .then(() => {
                                                                    return $ocLazyLoad.load([
                                                                        'js/config/froala.module.js'
                                                                    ])
                                                                        .then(() => {
                                                                            return $ocLazyLoad.load([
                                                                                'js/shared/wizardSteps/selectFreeFormatImage/selectFreeFormatImage.js',
                                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                                'js/shared/wizardSteps/moduleImage/moduleImage.js',
                                                                                'js/shared/wizardSteps/voucherTemplatePdf/voucherTemplatePdf.js',
                                                                                'js/shared/wizardSteps/moduleLandingPageImage/moduleLandingPageImage.js',
                                                                                'js/shared/wizardSteps/moduleEmailImage/moduleEmailImage.js',
                                                                                'js/shared/toArray/toArray.js',
                                                                                'js/shared/moduleScheduleManager/ModuleScheduleManager.js',
                                                                                'js/routes/modules/ModulesService.js',
                                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                                'js/shared/upload/UploadService.js',
                                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                                'app/shared/modals/confirmModal.html',
                                                                                'app/shared/modals/cardGuideModal.html',
                                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                                'js/shared/wizardSteps/moduleDetails/moduleDetails.js',
                                                                                'js/shared/wizardSteps/moduleSchedule/moduleSchedule.js',
                                                                                'js/shared/wizardSteps/freeFormatCommunicationsText/freeFormatCommunicationsText.js',
                                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                                'js/shared/wizardSteps/reminder/reminder.js',
                                                                                'js/shared/wizardSteps/freeModuleMaterialPreviews/freeModuleMaterialPreviews.js',
                                                                                'js/shared/wizardSteps/uploadMaterials/uploadMaterials.js',
                                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                                'js/shared/wizardSteps/samplePath/samplePath.js',
                                                                                'js/shared/wizardSteps/communicationKit/communicationKit.js',
                                                                                'js/shared/wizardSteps/uploadedMaterials/uploadedMaterials.js',
                                                                                'js/shared/wizardSteps/moduleVisibility/moduleVisibility.js',
                                                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                                                'js/shared/global/EditionsService.js',
                                                                                'js/routes/manageEdition/ManageEditionService.js',
                                                                                'js/shared/editionManager/EditionManager.js',
                                                                                'js/shared/actionsHeader/actionsHeader.js'])
                                                                                .then(() => {
                                                                                    $rootScope.forceLoadSpinner = false;
                                                                                    return $ocLazyLoad.load('js/routes/manageFreeModule/ManageFreeModuleController.js')
                                                                                });
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina con la lista di Meeting creati in un Format (lato formazione)
                    .state('app.customersinvitationsApp.meetingListOfFormat', {
                        url: '/meetingListOfFormat/:courseId/:isFreeFormat',
                        views: {
                            "content": { templateUrl: 'app/routes/meetingsOfModule/meetingsOfModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/editionsManager/EditionsManager.js',
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/routes/manageEdition/ManageEditionService.js',
                                                'js/shared/editionManager/EditionManager.js',
                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                'js/shared/agencyManager/AgencyManager.js',
                                                'js/shared/global/EditionsService.js',
                                                'js/shared/editionsManager/EditionsManager.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/routes/modules/ModulesService.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'js/shared/meetingsFilters/meetingsFilters.js',
                                                'js/shared/meetingsTable/meetingsTable.js',
                                                'js/routes/meetingsOfModule/MeetingsOfModuleService.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load('js/routes/meetingsOfModule/MeetingsOfModuleController.js')
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina con la lista di Meeting riservati per una Campagna (lato formazione)
                    .state('app.customersinvitationsApp.reservedMeetingListOfDirectional', {
                        url: '/reservedMeetingListOfDirectional/:courseId',
                        views: {
                            "content": { templateUrl: 'app/routes/meetingsOfModule/meetingsOfModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/routes/manageEdition/ManageEditionService.js',
                                                'js/shared/editionManager/EditionManager.js',
                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                'js/shared/agencyManager/AgencyManager.js',
                                                'js/shared/global/EditionsService.js',
                                                'js/shared/editionsManager/EditionsManager.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/routes/modules/ModulesService.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'js/shared/meetingsFilters/meetingsFilters.js',
                                                'js/shared/meetingsTable/meetingsTable.js',
                                                'js/routes/meetingsOfModule/MeetingsOfModuleService.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load('js/routes/meetingsOfModule/MeetingsOfModuleController.js')
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina con la lista di Meeting creati per una Campagna (lato formazione)
                    .state('app.customersinvitationsApp.createdMeetingListOfDirectional', {
                        url: '/createdMeetingListOfDirectional/:courseId',
                        views: {
                            "content": { templateUrl: 'app/routes/meetingsOfModule/meetingsOfModule.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/routes/manageEdition/ManageEditionService.js',
                                                'js/shared/editionManager/EditionManager.js',
                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                'js/shared/agencyManager/AgencyManager.js',
                                                'js/shared/global/EditionsService.js',
                                                'js/shared/editionsManager/EditionsManager.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/routes/modules/ModulesService.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'js/shared/meetingsFilters/meetingsFilters.js',
                                                'js/shared/meetingsTable/meetingsTable.js',
                                                'js/routes/meetingsOfModule/MeetingsOfModuleService.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load('js/routes/meetingsOfModule/MeetingsOfModuleController.js')
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina per la creazione di un'edizione di una campagna direzionale
                    .state('app.customersinvitationsApp.newDirectionalMeeting', {
                        url: '/newDirectionalMeeting',
                        views: {
                            "content": { templateUrl: 'app/routes/manageEdition/manageEdition.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        // Dipendenze di google maps
                                        'vendor/angular-simple-logger/angular-simple-logger.min.js',
                                        'vendor/lodash/lodash.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'assets/datetime-picker-custom.js',
                                                'vendor/material-steppers/material-steppers.css',
                                                'assets/js/material-steppers/material-steppers.js',
                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                'vendor/ng-file-upload/ng-file-upload.min.js',
                                                'vendor/angular-google-maps/angular-google-maps.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/config/ui.bootstrap.datetimepicker.module.js',
                                                        'js/config/uiGmapgoogle.module.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'js/routes/modules/ModulesService.js',
                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/shared/selectAllAndCopy/selectAllAndCopy.js',
                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/shared/upload/UploadService.js',
                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                'js/shared/wizardSteps/cardHeader/cardHeader.js',
                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                'js/shared/wizardSteps/previewCommunicationKit/previewCommunicationKit.js',
                                                                'app/shared/modals/confirmModal.html',
                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                'js/shared/global/EditionsService.js',
                                                                'js/shared/editionManager/EditionManager.js',
                                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                'js/shared/wizardSteps/users/users.js',
                                                                'js/shared/wizardSteps/monitorPresencesManager/monitorPresencesManager.js',
                                                                'js/shared/wizardSteps/adhesionInitiative/adhesionInitiative.js',
                                                                'js/shared/wizardSteps/adhesionInitiative/adhesionInitiative.js',
                                                                'js/shared/wizardSteps/editUser/editUser.js',
                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                'js/shared/wizardSteps/selectMeetingType/selectMeetingType.js',
                                                                'js/shared/wizardSteps/trainer/trainer.js',
                                                                'js/shared/wizardSteps/selectModule/selectModule.js',
                                                                'js/shared/wizardSteps/importUsersFromFile/importUsersFromFile.js',
                                                                'js/shared/wizardSteps/limitSeats/limitSeats.js',
                                                                'js/shared/wizardSteps/where/where.js',
                                                                'js/shared/agencyManager/AgencyManager.js',
                                                                'js/shared/managerManager/ManagerManager.js',
                                                                'js/shared/wizardSteps/when/when.js',
                                                                'js/shared/wizardSteps/selectAgency/selectAgency.js',
                                                                'js/shared/wizardSteps/selectManager/selectManager.js',
                                                                'js/shared/wizardSteps/assignSeats/assignSeats.js',
                                                                'js/shared/actionsHeader/actionsHeader.js',
                                                                'js/routes/manageEdition/ManageEditionService.js'])
                                                                .then(() => {
                                                                    $rootScope.forceLoadSpinner = false;
                                                                    return $ocLazyLoad.load('js/routes/manageEdition/ManageEditionController.js')
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina per la modifica di un'edizione di una campagna direzionale
                    .state('app.customersinvitationsApp.editDirectionalMeeting', {
                        url: '/editDirectionalMeeting/:courseId/:courseDateId/:joinId',
                        views: {
                            "content": { templateUrl: 'app/routes/manageEdition/manageEdition.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        // Dipendenze di google maps
                                        'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                        'vendor/angular-simple-logger/angular-simple-logger.min.js',
                                        'vendor/lodash/lodash.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                'assets/datetime-picker-custom.js',
                                                'vendor/material-steppers/material-steppers.css',
                                                'assets/js/material-steppers/material-steppers.js',
                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                'vendor/ng-file-upload/ng-file-upload.min.js',
                                                'vendor/angular-google-maps/angular-google-maps.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/config/ui.bootstrap.datetimepicker.module.js',
                                                        'js/config/uiGmapgoogle.module.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'js/routes/modules/ModulesService.js',
                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/shared/selectAllAndCopy/selectAllAndCopy.js',
                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/shared/upload/UploadService.js',
                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                'js/shared/wizardSteps/cardHeader/cardHeader.js',
                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                'js/shared/wizardSteps/previewCommunicationKit/previewCommunicationKit.js',
                                                                'app/shared/modals/confirmModal.html',
                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                'js/shared/global/EditionsService.js',
                                                                'js/shared/editionManager/EditionManager.js',
                                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                'js/shared/wizardSteps/users/users.js',
                                                                'js/shared/wizardSteps/editUser/editUser.js',
                                                                'js/shared/wizardSteps/signature/signature.js',
                                                                'js/shared/wizardSteps/adhesionInitiative/adhesionInitiative.js',
                                                                'js/shared/wizardSteps/monitorPresencesManager/monitorPresencesManager.js',
                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                'js/shared/wizardSteps/selectMeetingType/selectMeetingType.js',
                                                                'js/shared/wizardSteps/limitSeats/limitSeats.js',
                                                                'js/shared/wizardSteps/trainer/trainer.js',
                                                                'js/shared/wizardSteps/selectModule/selectModule.js',
                                                                'js/shared/wizardSteps/importUsersFromFile/importUsersFromFile.js',
                                                                'js/shared/wizardSteps/where/where.js',
                                                                'js/shared/wizardSteps/selectAgency/selectAgency.js',
                                                                'js/shared/wizardSteps/selectManager/selectManager.js',
                                                                'js/shared/wizardSteps/assignSeats/assignSeats.js',
                                                                'js/shared/agencyManager/AgencyManager.js',
                                                                'js/shared/managerManager/ManagerManager.js',
                                                                'js/shared/wizardSteps/when/when.js',
                                                                'js/shared/actionsHeader/actionsHeader.js',
                                                                'js/routes/manageEdition/ManageEditionService.js'])
                                                                .then(() => {
                                                                    $rootScope.forceLoadSpinner = false;
                                                                    return $ocLazyLoad.load('js/routes/manageEdition/ManageEditionController.js')
                                                                })
                                                        })
                                                })
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSE_DATES'
                        }
                    })

                    // Definizione della pagina per la creazione di un'edizione di un format
                    .state('app.customersinvitationsApp.newFormatMeeting', {
                        url: '/newFormatMeeting',
                        views: {
                            "content": { templateUrl: 'app/routes/manageEdition/manageEdition.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        // Dipendenze di google maps
                                        'vendor/angular-simple-logger/angular-simple-logger.min.js',
                                        'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                        'vendor/lodash/lodash.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                'assets/datetime-picker-custom.js',
                                                'vendor/material-steppers/material-steppers.css',
                                                'assets/js/material-steppers/material-steppers.js',
                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                'vendor/ng-file-upload/ng-file-upload.min.js',
                                                'vendor/angular-google-maps/angular-google-maps.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/config/ui.bootstrap.datetimepicker.module.js',
                                                        'js/config/simpleLogger.module.js',
                                                        'js/config/uiGmapgoogle.module.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'js/routes/modules/ModulesService.js',
                                                                'js/shared/selectAllAndCopy/selectAllAndCopy.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/shared/upload/UploadService.js',
                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                'js/shared/wizardSteps/cardHeader/cardHeader.js',
                                                                'js/shared/wizardSteps/previewCommunicationKit/previewCommunicationKit.js',
                                                                'app/shared/modals/confirmModal.html',
                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                'js/shared/global/EditionsService.js',
                                                                'js/shared/editionManager/EditionManager.js',
                                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                'js/shared/wizardSteps/monitorPresencesManager/monitorPresencesManager.js',
                                                                'js/shared/wizardSteps/users/users.js',
                                                                'js/shared/wizardSteps/editUser/editUser.js',
                                                                'js/shared/wizardSteps/signature/signature.js',
                                                                'js/shared/wizardSteps/adhesionInitiative/adhesionInitiative.js',
                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                'js/shared/wizardSteps/selectMeetingType/selectMeetingType.js',
                                                                'js/shared/wizardSteps/limitSeats/limitSeats.js',
                                                                'js/shared/wizardSteps/trainer/trainer.js',
                                                                'js/shared/wizardSteps/selectModule/selectModule.js',
                                                                'js/shared/wizardSteps/importUsersFromFile/importUsersFromFile.js',
                                                                'js/shared/wizardSteps/where/where.js',
                                                                'js/shared/wizardSteps/selectAgency/selectAgency.js',
                                                                'js/shared/wizardSteps/selectManager/selectManager.js',
                                                                'js/shared/wizardSteps/assignSeats/assignSeats.js',
                                                                'js/shared/agencyManager/AgencyManager.js',
                                                                'js/shared/managerManager/ManagerManager.js',
                                                                'js/shared/wizardSteps/when/when.js',
                                                                'js/shared/actionsHeader/actionsHeader.js',
                                                                'js/routes/manageEdition/ManageEditionService.js'])
                                                                .then(() => {
                                                                    $rootScope.forceLoadSpinner = false;
                                                                    return $ocLazyLoad.load('js/routes/manageEdition/ManageEditionController.js')
                                                                })
                                                        })
                                                })
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSE_DATES'
                        }
                    })

                    // Definizione della pagina per la modifica di un'edizione di un format
                    .state('app.customersinvitationsApp.editFormatMeeting', {
                        url: '/editFormatMeeting/:courseId/:courseDateId/:joinId',
                        views: {
                            "content": { templateUrl: 'app/routes/manageEdition/manageEdition.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        // Dipendenze di google maps
                                        'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                        'vendor/angular-simple-logger/angular-simple-logger.min.js',
                                        'vendor/lodash/lodash.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                'assets/datetime-picker-custom.js',
                                                'vendor/material-steppers/material-steppers.css',
                                                'assets/js/material-steppers/material-steppers.js',
                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                'vendor/ng-file-upload/ng-file-upload.min.js',
                                                'vendor/angular-google-maps/angular-google-maps.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/config/ui.bootstrap.datetimepicker.module.js',
                                                        'js/config/uiGmapgoogle.module.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'js/routes/modules/ModulesService.js',
                                                                'js/shared/selectAllAndCopy/selectAllAndCopy.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/shared/upload/UploadService.js',
                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                'js/shared/wizardSteps/cardHeader/cardHeader.js',
                                                                'js/shared/wizardSteps/previewCommunicationKit/previewCommunicationKit.js',
                                                                'app/shared/modals/confirmModal.html',
                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                'js/shared/global/EditionsService.js',
                                                                'js/shared/editionManager/EditionManager.js',
                                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                'js/shared/wizardSteps/monitorPresencesManager/monitorPresencesManager.js',
                                                                'js/shared/wizardSteps/users/users.js',
                                                                'js/shared/wizardSteps/editUser/editUser.js',
                                                                'js/shared/wizardSteps/signature/signature.js',
                                                                'js/shared/wizardSteps/adhesionInitiative/adhesionInitiative.js',
                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                'js/shared/wizardSteps/selectMeetingType/selectMeetingType.js',
                                                                'js/shared/wizardSteps/limitSeats/limitSeats.js',
                                                                'js/shared/wizardSteps/trainer/trainer.js',
                                                                'js/shared/wizardSteps/selectModule/selectModule.js',
                                                                'js/shared/wizardSteps/importUsersFromFile/importUsersFromFile.js',
                                                                'js/shared/wizardSteps/where/where.js',
                                                                'js/shared/wizardSteps/selectAgency/selectAgency.js',
                                                                'js/shared/wizardSteps/selectManager/selectManager.js',
                                                                'js/shared/wizardSteps/assignSeats/assignSeats.js',
                                                                'js/shared/agencyManager/AgencyManager.js',
                                                                'js/shared/managerManager/ManagerManager.js',
                                                                'js/shared/wizardSteps/when/when.js',
                                                                'js/shared/actionsHeader/actionsHeader.js',
                                                                'js/routes/manageEdition/ManageEditionService.js'])
                                                                .then(() => {
                                                                    $rootScope.forceLoadSpinner = false;
                                                                    return $ocLazyLoad.load('js/routes/manageEdition/ManageEditionController.js')
                                                                })
                                                        });
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSE_DATES'
                        }
                    })

                    // Definizione della pagina per la modifica di un'edizione online on demand di un format (è la stessa route di 'editFormatMeeting', ma se ne è fatta un'altra per abilitare il pulsante corretto nel menu dell'header)
                    .state('app.customersinvitationsApp.editFormatMeetingOnlineOnDemand', {
                        url: '/editFormatMeetingOnlineOnDemand/:courseId/:courseDateId/:joinId',
                        views: {
                            "content": { templateUrl: 'app/routes/manageEdition/manageEdition.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad', '$rootScope',
                                ($ocLazyLoad: any, $rootScope: any) => {
                                    $rootScope.forceLoadSpinner = true;
                                    return $ocLazyLoad.load([
                                        // Dipendenze di google maps
                                        'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css',
                                        'vendor/angular-simple-logger/angular-simple-logger.min.js',
                                        'vendor/lodash/lodash.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                'assets/datetime-picker-custom.js',
                                                'vendor/material-steppers/material-steppers.css',
                                                'assets/js/material-steppers/material-steppers.js',
                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                'vendor/ng-file-upload/ng-file-upload.min.js',
                                                'vendor/angular-google-maps/angular-google-maps.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/config/ui.bootstrap.datetimepicker.module.js',
                                                        'js/config/uiGmapgoogle.module.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                'js/routes/modules/ModulesService.js',
                                                                'js/shared/selectAllAndCopy/selectAllAndCopy.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/routes/manageModule/ManageModuleService.js',
                                                                'js/shared/courseAdmin/CommunicationService.js',
                                                                'js/shared/vouchersManager/VouchersManager.js',
                                                                'js/shared/upload/UploadService.js',
                                                                'js/shared/materialsManager/MaterialsManager.js',
                                                                'app/shared/modals/kalturaVideoModal.html',
                                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                                'js/shared/wizardSteps/cardHeader/cardHeader.js',
                                                                'js/shared/wizardSteps/previewCommunicationKit/previewCommunicationKit.js',
                                                                'app/shared/modals/confirmModal.html',
                                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                                'js/shared/modulesManager/ModulesManager.js',
                                                                'js/shared/global/EditionsService.js',
                                                                'js/shared/editionManager/EditionManager.js',
                                                                'js/shared/trainingsManager/TrainingsManager.js',
                                                                'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                                'js/shared/wizardSteps/monitorPresencesManager/monitorPresencesManager.js',
                                                                'js/shared/wizardSteps/users/users.js',
                                                                'js/shared/wizardSteps/editUser/editUser.js',
                                                                'js/shared/wizardSteps/signature/signature.js',
                                                                'js/shared/wizardSteps/adhesionInitiative/adhesionInitiative.js',
                                                                'js/shared/wizardSteps/communications/communications.js',
                                                                'js/shared/wizardSteps/letter/letter.js',
                                                                'js/shared/wizardSteps/selectMeetingType/selectMeetingType.js',
                                                                'js/shared/wizardSteps/limitSeats/limitSeats.js',
                                                                'js/shared/wizardSteps/trainer/trainer.js',
                                                                'js/shared/wizardSteps/selectModule/selectModule.js',
                                                                'js/shared/wizardSteps/importUsersFromFile/importUsersFromFile.js',
                                                                'js/shared/wizardSteps/where/where.js',
                                                                'js/shared/wizardSteps/selectAgency/selectAgency.js',
                                                                'js/shared/wizardSteps/selectManager/selectManager.js',
                                                                'js/shared/wizardSteps/assignSeats/assignSeats.js',
                                                                'js/shared/agencyManager/AgencyManager.js',
                                                                'js/shared/managerManager/ManagerManager.js',
                                                                'js/shared/wizardSteps/when/when.js',
                                                                'js/shared/actionsHeader/actionsHeader.js',
                                                                'js/routes/manageEdition/ManageEditionService.js'])
                                                                .then(() => {
                                                                    $rootScope.forceLoadSpinner = false;
                                                                    return $ocLazyLoad.load('js/routes/manageEdition/ManageEditionController.js')
                                                                })
                                                        });
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSE_DATES'
                        }
                    })

                    // Definizione della pagina con la lista di campagne
                    .state('app.customersinvitationsApp.directionals', {
                        url: '/directionals',
                        views: {
                            "content": { templateUrl: 'app/routes/modules/modules.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'app/shared/modals/confirmModal.html',
                                                'js/routes/modules/ModulesService.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'js/shared/wizardSteps/selectModule/selectModule.js',
                                                'js/shared/modulesTable/modulesTable.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/shared/wizardSteps/confirmButton/confirmButton.js',
                                                        'js/routes/modules/ModulesController.js'])
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_MANAGE_COURSES'
                        }
                    })

                    // Definizione della pagina con la lista dei moduli di tipo format
                    .state('app.customersinvitationsApp.format', {
                        url: '/format',
                        views: {
                            "content": { templateUrl: 'app/routes/modules/modules.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'app/shared/modals/confirmModal.html',
                                                'js/routes/modules/ModulesService.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'js/shared/modulesTable/modulesTable.js',
                                                'js/routes/modules/ModulesService.js',
                                                'js/routes/modules/ModulesController.js'
                                            ])
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina con la lista dei format liberi
                    .state('app.customersinvitationsApp.freeFormatList', {
                        url: '/freeFormatList',
                        views: {
                            "content": { templateUrl: 'app/routes/freeFormatList/freeFormatList.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'app/shared/modals/confirmModal.html',
                                                'js/routes/modules/ModulesService.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'js/shared/modulesTable/modulesTable.js',
                                                'js/routes/freeFormatList/FreeFormatListService.js',
                                                'js/routes/freeFormatList/FreeFormatListController.js'
                                            ])
                                        })
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina per visualizzare la lista dei moduli con i loro materiali
                    .state('app.customersinvitationsApp.materials', {
                        url: '/materials',
                        views: {
                            "content": { templateUrl: 'app/routes/materials/materials.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                'js/routes/modules/ModulesService.js',
                                                'js/routes/manageModule/ManageModuleService.js',
                                                'js/shared/courseAdmin/CommunicationService.js',
                                                'js/shared/partecipantsManager/PartecipantsManager.js',
                                                'js/shared/vouchersManager/VouchersManager.js',
                                                'js/shared/upload/UploadService.js',
                                                'js/shared/materialsManager/MaterialsManager.js',
                                                'js/shared/modulesManager/ModulesManager.js',
                                                'app/shared/modals/kalturaVideoModal.html',
                                                'js/shared/wizardSteps/uploadMaterials/uploadMaterials.js',
                                                'js/shared/wizardSteps/uploadedMaterials/uploadedMaterials.js',
                                                'js/shared/actionsHeader/actionsHeader.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load('js/routes/materials/MaterialsController.js')
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina per visualizzare la lista dei report scaricabili
                    .state('app.customersinvitationsApp.report', {
                        url: '/report',
                        views: {
                            "content": { templateUrl: 'app/routes/report/report.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                'vendor/ng-file-upload/ng-file-upload.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/shared/courseAdmin/CommunicationService.js',
                                                        'js/shared/partecipantsManager/PartecipantsManager.js',
                                                        'js/shared/vouchersManager/VouchersManager.js',
                                                        'js/shared/upload/UploadService.js',
                                                        'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                        'js/routes/modules/ModulesService.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load('js/routes/report/ReportController.js')
                                                        });
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina per gestire le immagini dei format liberi
                    .state('app.customersinvitationsApp.freeFormatImages', {
                        url: '/freeFormatImages',
                        views: {
                            "content": { templateUrl: 'app/routes/freeFormatImages/freeFormatImages.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'vendor/lf-ng-md-file-input/lf-ng-md-file-input.css'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'vendor/lf-ng-md-file-input/lf-ng-md-file-input.js',
                                                'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                                'vendor/ng-file-upload/ng-file-upload.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'app/shared/modals/confirmModal.html',
                                                        'js/routes/modules/ModulesService.js',
                                                        'js/routes/manageModule/ManageModuleService.js',
                                                        'js/shared/courseAdmin/CommunicationService.js',
                                                        'js/shared/modulesManager/ModulesManager.js',
                                                        'js/shared/materialsManager/MaterialsManager.js',
                                                        'js/shared/courseAdmin/CommunicationService.js',
                                                        'js/shared/partecipantsManager/PartecipantsManager.js',
                                                        'js/shared/vouchersManager/VouchersManager.js',
                                                        'js/shared/upload/UploadService.js',
                                                        'js/shared/wizardSteps/cardTitleAndDescription/cardTitleAndDescription.js',
                                                        'js/routes/modules/ModulesService.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load('js/routes/freeFormatImages/FreeFormatImagesController.js')
                                                        });
                                                });
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_USE_COURSE'
                        }
                    })

                    // Definizione della pagina con la lista di docenti
                    .state('app.customersinvitationsApp.teachers', {
                        url: '/teachers',
                        views: {
                            "content": { templateUrl: 'app/routes/teachers/teachers.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/teachers/TeachersController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'CUSTOMERSINVITATIONS_VIEW_TEACHERS'
                        }
                    })

                    // Pagina per l'iscrizione di un utente
                    .state("app.access.landingPage", {
                        url: "/landingPage/:courseId/:courseDateId/:userId/:tenant/:langCode",
                        templateUrl: "app/routes/landingPage/landingPage.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/js/grapesjs/css/toastr.min.css',
                                        'vendor/angular-socialshare/angular-socialshare.min.js',
                                        'vendor/angular-material-icons/angular-material-icons.js',
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'assets/js/grapesjs/js/toastr.min.js',
                                                'js/config/socialshare.module.js',
                                                'js/config/ngMdIcons.module.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        'js/shared/toArray/toArray.js',
                                                        'js/routes/modules/ModulesService.js',
                                                        'js/routes/manageModule/ManageModuleService.js',
                                                        'js/shared/courseAdmin/CommunicationService.js',
                                                        'js/shared/vouchersManager/VouchersManager.js',
                                                        'js/shared/upload/UploadService.js',
                                                        'js/shared/materialsManager/MaterialsManager.js',
                                                        'js/shared/moduleScheduleManager/ModuleScheduleManager.js',
                                                        'js/shared/partecipantsManager/PartecipantsManager.js',
                                                        'js/shared/modulesManager/ModulesManager.js',
                                                        'js/shared/global/EditionsService.js',
                                                        'js/shared/editionManager/EditionManager.js',
                                                        'js/shared/trainingsManager/TrainingsManager.js',
                                                        'js/routes/manageEdition/ManageEditionService.js'])
                                                        .then(() => {
                                                            return $ocLazyLoad.load([
                                                                "js/routes/landingPage/LandingPageService.js",
                                                                "js/routes/landingPage/LandingPageController.js"])
                                                        });
                                                });
                                        });
                                }]
                        }
                    })

                    /*
                                        // Pagina per inoltrare un invito ad un amico
                                        .state("app.access.forwardInviteToAFriend", {
                                            url: "/forwardInviteToAFriend/:courseId/:courseDateId/:userId",
                                            templateUrl: "app/routes/forwardInviteToAFriend/forwardInviteToAFriend.html",
                                            resolve: {
                                                deps: ["$ocLazyLoad",
                                                    ($ocLazyLoad: any) => {
                                                        return $ocLazyLoad.load([
                                                            'app/shared/modals/confirmModal.html',
                                                            'assets/js/grapesjs/css/toastr.min.css',
                                                            'vendor/angular-socialshare/angular-socialshare.min.js',
                                                            'vendor/angular-material-icons/angular-material-icons.js'])
                                                            .then(() => {
                                                                return $ocLazyLoad.load([
                                                                    'assets/js/grapesjs/js/toastr.min.js',
                                                                    'js/config/socialshare.module.js',
                                                                    'js/config/ngMdIcons.module.js'])
                                                                    .then(() => {
                                                                        return $ocLazyLoad.load([
                                                                            "js/routes/forwardInviteToAFriend/ForwardInviteToAFriendService.js",
                                                                            "js/routes/forwardInviteToAFriend/ForwardInviteToAFriendController.js"])
                                                                    });
                                                            });
                                                    }]
                                            }
                                        })
                    */

                    // Pagina per la validazione di un format libero (lato manager)
                    .state("app.access.managerFreeFormatValidation", {
                        url: "/managerFreeFormatValidation/:courseId/:acceptFormat",
                        templateUrl: "app/routes/freeFormatValidation/freeFormatValidation.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/js/grapesjs/css/toastr.min.css',
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'assets/js/grapesjs/js/toastr.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        "js/routes/freeFormatValidation/FreeFormatValidationService.js",
                                                        "js/routes/freeFormatValidation/FreeFormatValidationController.js"])
                                                });
                                        });
                                }]
                        }
                    })

                    // Pagina per la validazione di un format libero (lato marketing)
                    .state("app.access.marketingFreeFormatValidation", {
                        url: "/marketingFreeFormatValidation/:courseId/:acceptFormat",
                        templateUrl: "app/routes/freeFormatValidation/freeFormatValidation.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/js/grapesjs/css/toastr.min.css',
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'assets/js/grapesjs/js/toastr.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        "js/routes/freeFormatValidation/FreeFormatValidationService.js",
                                                        "js/routes/freeFormatValidation/FreeFormatValidationController.js"])
                                                });
                                        });
                                }]
                        }
                    })

                    // Pagina per scaricare i materiali di un format libero
                    .state("app.access.freeFormatValidationMaterials", {
                        url: "/freeFormatValidationMaterials/:courseId/:userId/:tenant/:langCode",
                        templateUrl: "app/routes/freeFormatValidationMaterials/freeFormatValidationMaterials.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/js/grapesjs/css/toastr.min.css',
                                        'vendor/ng-file-upload/ng-file-upload-shim.min.js',
                                        'vendor/ng-file-upload/ng-file-upload.min.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load([
                                                'assets/js/grapesjs/js/toastr.min.js'])
                                                .then(() => {
                                                    return $ocLazyLoad.load([
                                                        "js/routes/freeFormatValidationMaterials/FreeFormatValidationMaterialsService.js",
                                                        "js/routes/freeFormatValidationMaterials/FreeFormatValidationMaterialsController.js"])
                                                });
                                        });
                                }]
                        }
                    })

                    .state("app.access.error", {
                        url: "/error/:errorCode",
                        templateUrl: "tpl/page_error.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(["js/controllers/ErrorCtrl.js"]);
                                }]
                        }
                    })
                    .state("app.access.403", {
                        url: "/403",
                        templateUrl: "app/routes/errors/page_403.html"
                    })
                    .state("app.access.404", {
                        url: "/404",
                        templateUrl: "app/routes/errors/page_404.html"
                    })
            }])

    // Definisco l'interceptor per iniettare nell'header il token e che identifica la risposta "necessario login"
    .factory('tokenInjectInterceptor', ($q: angular.IQService, $location: ng.ILocationService, $sessionStorage: any, GlobalApplicationData: any, CustomersinvitationsApplicationData: any, $injector: ng.auto.IInjectorService) => {
        return {
            request: (config: any) => {
                if (config.url.indexOf("retrieve-token-after-login") === -1) {
                config.headers = config.headers || {};
                // Mi salvo dove era richiesto andare (se non ne ho già uno e se è il primo giro) se non sono le pagine di "sistema"
                if (!$sessionStorage.originalRequestedURL && $location.path().indexOf("/login") === -1 && $location.path().indexOf("/home") === -1 &&
                    $location.path().indexOf("/localLogin") === -1 && $location.path().indexOf("/adminLogin") === -1 && $location.path().indexOf("/app/intermediate") === -1 &&
                    firstPageVisited) {
                    $sessionStorage.originalRequestedURL = $location.path();
                }
                firstPageVisited = false;
                if ($sessionStorage.identityToken) {
                    config.headers.Authorization = 'Bearer ' + $sessionStorage.identityToken;
                }
                else {
                    // Dato che non ce l'ho, verifico se mi è arrivato un identity token in url, nel qual caso lo sostituisco all'eventuale esistente in session storage (e quindi poi anche in GlobalApplicationData)
                    let params = $location.search();
                    const localStorageToken = localStorage.getItem("token");
                    let skipSso: any;
                    if (params) {
                        if (localStorageToken) {
                            $sessionStorage.identityToken = localStorageToken;
                            localStorage.removeItem("token");
                        }
                        if (params.skipSso) {
                            skipSso = (params.skipSso === "true" || params.skipSso === true);
                        }

                        if(params.token) {
                            $sessionStorage.identityToken = params.token;
                            if (config.url && (config.url.indexOf("app/routes/login/login.html") === -1 && config.url.indexOf("app/routes/login/adminLogin.html") === -1 && config.url.indexOf("app/routes/login/ssoLogin.html") === -1 && config.url.indexOf("app/routes/landingPage/landingPage.html") === -1 && config.url.indexOf("app/routes/freeFormatValidation/freeFormatValidation.html") === -1 && config.url.indexOf("app/routes/freeFormatValidationMaterials/freeFormatValidationMaterials.html") === -1 && config.url.indexOf("app/shared/global/commonServices.html") === -1)) {
                                // Mi salvo dove era richiesto andare (se non ne ho già uno)
                                if (!GlobalApplicationData.orginalRequestedURL && ($location.path().indexOf("/login") === -1 && $location.path().indexOf("/adminLogin") === -1 && $location.path().indexOf("/landingPage") === -1 && $location.path().indexOf("/freeFormatValidation") === -1 && $location.path().indexOf("/freeFormatValidationMaterials") === -1 && $location.path().indexOf("/csessologin") === -1)) {
                                    GlobalApplicationData.orginalRequestedURL = $location.path();
                                }
                                let $state: any = $injector.get("$state");
                                if (!$state.current || ($state.current.data && $state.current.data.requiredAuthId)) {
                                    if (skipSso) {
                                        $state.go("app.localLogin");
                                    } else {
                                        $state.go("app.login");
                                    }
                                }
                            }
                        } else if(params.ssortkqp) {
                            var UserService: any = $injector.get('UserService');
                                UserService.getTokenFromSSOKey.query({ retrieveKey: params.ssortkqp }).$promise
                                    .then((response: any) => {
                                        if(response.error) {

                                        } else if(response.response) {
                                        $sessionStorage.identityToken = response.response;
                                        if (config.url && (config.url.indexOf("app/routes/login/login.html") === -1 && config.url.indexOf("app/routes/login/adminLogin.html") === -1 && config.url.indexOf("app/routes/login/ssoLogin.html") === -1 && config.url.indexOf("app/routes/landingPage/landingPage.html") === -1 && config.url.indexOf("app/routes/freeFormatValidation/freeFormatValidation.html") === -1 && config.url.indexOf("app/routes/freeFormatValidationMaterials/freeFormatValidationMaterials.html") === -1 && config.url.indexOf("app/shared/global/commonServices.html") === -1)) {
                                            // Mi salvo dove era richiesto andare (se non ne ho già uno)
                                            if (!GlobalApplicationData.orginalRequestedURL && ($location.path().indexOf("/login") === -1 && $location.path().indexOf("/adminLogin") === -1 && $location.path().indexOf("/landingPage") === -1 && $location.path().indexOf("/freeFormatValidation") === -1 && $location.path().indexOf("/freeFormatValidationMaterials") === -1 && $location.path().indexOf("/csessologin") === -1)) {
                                                GlobalApplicationData.orginalRequestedURL = $location.path();
                                            }
                                            let $state: any = $injector.get("$state");
                                            if (!$state.current || ($state.current.data && $state.current.data.requiredAuthId)) {
                                                if (skipSso) {
                                                    $state.go("app.localLogin");
                                                } else {
                                                    $state.go("app.login");
                                                }
                                            }
                                        }
                                        }
                                    })
                                    .catch((ex: Error) => {
                                        let $state: any = $injector.get("$state");
                                        $state.go("app.login");
                                    })
                        } else {
                            if (config.url && (config.url.indexOf("app/routes/login/login.html") === -1 && config.url.indexOf("app/routes/login/adminLogin.html") === -1 && config.url.indexOf("app/routes/login/ssoLogin.html") === -1 && config.url.indexOf("app/routes/landingPage/landingPage.html") === -1 && config.url.indexOf("app/routes/freeFormatValidation/freeFormatValidation.html") === -1 && config.url.indexOf("app/routes/freeFormatValidationMaterials/freeFormatValidationMaterials.html") === -1 && config.url.indexOf("app/shared/global/commonServices.html") === -1)) {
                                // Mi salvo dove era richiesto andare (se non ne ho già uno)
                                if (!GlobalApplicationData.orginalRequestedURL && ($location.path().indexOf("/login") === -1 && $location.path().indexOf("/adminLogin") === -1 && $location.path().indexOf("/landingPage") === -1 && $location.path().indexOf("/freeFormatValidation") === -1 && $location.path().indexOf("/freeFormatValidationMaterials") === -1 && $location.path().indexOf("/csessologin") === -1)) {
                                    GlobalApplicationData.orginalRequestedURL = $location.path();
                                }
                                let $state: any = $injector.get("$state");
                                if (!$state.current || ($state.current.data && $state.current.data.requiredAuthId)) {
                                    if (skipSso) {
                                        $state.go("app.localLogin");
                                    } else {
                                        $state.go("app.login");
                                    }
                                }
                            }
                        }
                    } else {
                    // Non ho i dati, quindi vado alla pagina che cerca o ricrea il token di autenticazione
                    // Ammesso che non stia già andando lì... (o stia caricando il main template che fa parte del "lì")
                    if (config.url && (config.url.indexOf("app/routes/login/login.html") === -1 && config.url.indexOf("app/routes/login/adminLogin.html") === -1 && config.url.indexOf("app/routes/login/ssoLogin.html") === -1 && config.url.indexOf("app/routes/landingPage/landingPage.html") === -1 && config.url.indexOf("app/routes/freeFormatValidation/freeFormatValidation.html") === -1 && config.url.indexOf("app/routes/freeFormatValidationMaterials/freeFormatValidationMaterials.html") === -1 && config.url.indexOf("app/shared/global/commonServices.html") === -1)) {
                        // Mi salvo dove era richiesto andare (se non ne ho già uno)
                        if (!GlobalApplicationData.orginalRequestedURL && ($location.path().indexOf("/login") === -1 && $location.path().indexOf("/adminLogin") === -1 && $location.path().indexOf("/landingPage") === -1 && $location.path().indexOf("/freeFormatValidation") === -1 && $location.path().indexOf("/freeFormatValidationMaterials") === -1 && $location.path().indexOf("/csessologin") === -1)) {
                            GlobalApplicationData.orginalRequestedURL = $location.path();
                        }
                        let $state: any = $injector.get("$state");
                        if (!$state.current || ($state.current.data && $state.current.data.requiredAuthId)) {
                            if (skipSso) {
                                $state.go("app.localLogin");
                            } else {
                                $state.go("app.login");
                            }
                        }
                    }
                    }
                }
            }

                return config;
            },

            response: (response: any) => {
                // Le risposte dei servizi possono contenere una SenecaResponse che ha un codice di errore e la risposta
                // Nel caso il codice sia uno specifico (equivalente del 401 del responseError), allora butto fuori l'utente
                if (response && response.data && response.data.error &&
                    (response.data.error === CustomersinvitationsApplicationData.constants.LOGGED_USER_NOT_FOUND || response.data.error === CustomersinvitationsApplicationData.constants.USER_WITHOUT_AUTHS)) {
                    // La sessione utente è sicuramente scaduta, quindi cancello anche il token
                    $sessionStorage.identityToken = null;
                    GlobalApplicationData.jwtPayload = null;
                    GlobalApplicationData.showSessionExpiredAlert = true;
                    // Vado alla pagina che cerca o ricrea il token di autenticazione
                    //$location.path('#/app/authenticating');
                    if ($location.path().indexOf("/landingPage") === -1 && $location.path().indexOf("/freeFormatValidation") === -1 && $location.path().indexOf("/freeFormatValidationMaterials") === -1) {
                        var $state: any = $injector.get("$state");
                        $state.go("app.login");
                    }
                }
                return response;
            },

            responseError: (rejection: any) => {
                if (rejection.status == 401) {
                    // La sessione utente è sicuramente scaduta, quindi cancello anche il token
                    $sessionStorage.identityToken = null;
                    GlobalApplicationData.jwtPayload = null;
                    GlobalApplicationData.showSessionExpiredAlert = true;
                    // Vado alla pagina che cerca o ricrea il token di autenticazione
                    //$location.path('#/app/authenticating');
                    var $state: any = $injector.get("$state");
                    $state.go("app.login");
                }
                return $q.reject(rejection);
            }
        };
    })

    .config(['$httpProvider', ($httpProvider: angular.IHttpProvider) => {
        $httpProvider.interceptors.push('tokenInjectInterceptor');
    }])

    // Definisco il listener per il cambio di state (pagina) che controlla i diritti degli utenti
    .run(($rootScope: ng.IScope, $state: angular.ui.IStateService, UserService: any, GlobalApplicationData: any, $sessionStorage: any, $location: any) => {
        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            if (toState.name == "app.login" && fromState.name) {
                $sessionStorage.originalRequestedURL = $location.path();
            }
            // Se ho dei parametri impostati per i diritti di visibilità
            if (toState.data && toState.data.requiredAuthId) {
                // Verifico se ho già fatto un login o sto andando lì
                if (GlobalApplicationData.jwtPayload || toState.name === "app.login") {
                    // Verifico se sono abilitato a vederli
                    if (!UserService.isUserAuthorized(toState.data.requiredAuthId)) {
                        event.preventDefault();
                        //return false;
                        $state.go('app.access.403');
                    }
                }
                else {
                    // Non ho ancora fatto login, quindi mando l'utente alla pagina dedicata
                    event.preventDefault();
                    $state.go("app.login");
                }
            }
        })
        $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            if ($location.path().indexOf("/login") === -1 && $location.path().indexOf("/localLogin") === -1 && $location.path().indexOf("/app/intermediate") === -1) {
                $sessionStorage.originalRequestedURL = $location.path();
            }
        })
    })